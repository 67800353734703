import React, { useContext } from 'react';
import { Redirect, Link } from 'react-router-dom';
import { AppContext } from '../data/state';
import {
  IonContent,
  IonPage,
  IonButton,
  IonGrid,
  IonRow,
  IonCol
} from '@ionic/react';
import './Landing.css';
import birthdaysAiLogo from '../images/birthdays-ai-logo-white.png';

const Landing = () => {
  const { state } = useContext(AppContext);

  if (state.user) {
    return <Redirect to="/my/home" />;
  }

  return (
    <IonPage>
      <IonContent className="landing-page">
        <IonGrid>
          <IonRow>
            <IonCol
              sizeLg="4"
              offsetLg="4"
              sizeMd="6"
              offsetMd="3"
              sizeSm="8"
              offsetSm="2"
              sizeXs="10"
              offsetXs="1"
            >
              <div className="flex-layout">
                <div className="logo">
                  <img
                    className="ion-text-center"
                    src={birthdaysAiLogo}
                    alt="Birthdays AI logo"
                  />
                  <p>keeping track of birthdays for you</p>
                  <p className="welcome">You're welcome ❤</p>
                </div>
                <IonButton
                  className="ion-margin-bottom primary-button"
                  routerLink="/register"
                >
                  Sign Up Free
                </IonButton>
                <div className="footer">
                  <p className="ion-text-center">
                    Already have an account? <Link to="/login">Log in</Link>
                  </p>
                </div>
              </div>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default Landing;
