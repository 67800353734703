import React, { createContext, useReducer } from 'react';

export const AppContext = createContext();

const initialState = {
  user: null,
  userDetails: null,
  birthdays: null
};

// actions
export const FIREBASE_USER = 'user logged in';
export const BIRTHDAYS = 'birthdays';
export const USER_DETAILS = 'user details';

// reducer
const reducer = (state, action) => {
  if (action.type === FIREBASE_USER) {
    return { ...state, user: action.payload };
  } else if (action.type === BIRTHDAYS) {
    return { ...state, birthdays: action.payload };
  } else if (action.type === USER_DETAILS) {
    return { ...state, userDetails: action.payload };
  }
  return state;
};

export const AppContextProvider = props => {
  let [state, dispatch] = useReducer(reducer, initialState);

  let value = { state, dispatch };

  return (
    <AppContext.Provider value={value}>{props.children}</AppContext.Provider>
  );
};
