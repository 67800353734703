import React, { useContext } from 'react';
import { Redirect } from 'react-router-dom';
import { IonContent, IonPage, IonButton } from '@ionic/react';
import { AppContext } from '../data/state';
import { firebase } from '../modules/firebase';
import MainToolbar from '../components/MainToolbar';
import './Profile.css';

const Profile = () => {
  const { state } = useContext(AppContext);

  if (!state.user) {
    return <Redirect to="/login" />;
  }

  return (
    <IonPage id="profile">
      <MainToolbar />
      <IonContent fullscreen className="ion-padding">
        <h3>{state.userDetails.name}</h3>
        <p>Timezone: {state.userDetails.timezone}</p>
        <IonButton
          className="ion-margin-vertical primary-button"
          onClick={() => firebase.auth().signOut()}
        >
          Sign out
        </IonButton>
      </IonContent>
    </IonPage>
  );
};

export default Profile;
