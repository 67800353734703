import React, { useContext } from 'react';
import { Redirect } from 'react-router-dom';
import { DateTime } from 'luxon';
import { AppContext } from '../data/state';
import {
  IonContent,
  IonPage,
  IonLoading,
  IonCardContent,
  IonCard,
  IonGrid,
  IonRow,
  IonCol,
  IonCardHeader,
  IonCardTitle
} from '@ionic/react';
import { sortBirthdays } from '../modules/utils';
import birthdaysAiLogo from '../images/birthdays-ai-logo-white.png';
import './Home.css';

const Home = () => {
  const { state } = useContext(AppContext);

  if (!state.user) {
    return <Redirect to="/login" />;
  }

  if (!state.birthdays || !state.userDetails) {
    return (
      <IonLoading isOpen={!state.birthdays} message={'Loading happiness...'} />
    );
  }

  const upcomingBirthdayPerson = daysTillBirthday => {
    if (daysTillBirthday > 0 && daysTillBirthday <= 1) {
      return 'tomorrow';
    } else if (daysTillBirthday <= 0 && daysTillBirthday > -1) {
      return 'today';
    } else {
      return `${Math.abs(Math.ceil(daysTillBirthday))} days`;
    }
  };

  return (
    <IonPage id="home">
      <IonContent fullscreen>
        <IonGrid>
          <IonRow>
            <IonCol
              sizeLg="4"
              offsetLg="4"
              sizeMd="6"
              offsetMd="3"
              sizeSm="8"
              offsetSm="2"
              sizeXs="10"
              offsetXs="1"
            >
              <div className="flex-layout">
                <div className="logo">
                  <img
                    className="ion-text-center"
                    src={birthdaysAiLogo}
                    alt="Birthdays AI logo"
                  />
                  <p>keeping track of birthdays for you</p>
                </div>
                <IonCard>
                  <IonCardHeader>
                    <IonCardTitle>Upcoming Birthdays</IonCardTitle>
                  </IonCardHeader>
                  <IonCardContent>
                    <IonGrid>
                      {sortBirthdays(state)
                        .slice(0, 3)
                        .map(key => (
                          <IonRow key={key}>
                            <IonCol>
                              <h2>{state.birthdays[key].name}</h2>
                            </IonCol>
                            <IonCol className="ion-text-end">
                              {upcomingBirthdayPerson(
                                DateTime.fromObject({
                                  day: state.birthdays[key].dob.day,
                                  month: state.birthdays[key].dob.month
                                })
                                  .diffNow('days')
                                  .toObject().days
                              )}
                            </IonCol>
                          </IonRow>
                        ))}
                    </IonGrid>
                  </IonCardContent>
                </IonCard>
              </div>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default Home;
