import React, { useContext, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { AppContext } from '../data/state';
import { firebase } from '../modules/firebase';
import { getTimezone } from '../modules/utils';
import {
  IonContent,
  IonPage,
  IonButton,
  IonLoading,
  IonInput,
  IonNote,
  IonGrid,
  IonRow,
  IonCol,
  IonAlert,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonIcon
} from '@ionic/react';
import { arrowBackOutline } from 'ionicons/icons';
import birthdaysAiLogo from '../images/birthdays-ai-logo-black.png';
import './Login.css';

const Login = () => {
  const [loginClicked, setLoginClicked] = useState(false);
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [showPasswordReset, setShowPasswordReset] = useState(false);
  const [error, setError] = useState();
  const { state } = useContext(AppContext);

  // update timezone on every login
  const logTimezone = uid =>
    firebase.firestore().collection('users').doc(uid).update({
      timezone: getTimezone()
    });

  if (state.user) {
    logTimezone(state.user.uid);
    return <Redirect to="/my/home" />;
  }

  return (
    <IonPage id="login">
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton routerLink="/" routerDirection="back">
              <IonIcon slot="icon-only" icon={arrowBackOutline} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen className="ion-padding">
        <IonGrid>
          <IonRow>
            <IonCol
              sizeLg="4"
              offsetLg="4"
              sizeMd="6"
              offsetMd="3"
              sizeSm="8"
              offsetSm="2"
              sizeXs="10"
              offsetXs="1"
              className="login-form"
            >
              <img
                className="ion-text-center logo"
                src={birthdaysAiLogo}
                alt="Birthdays AI logo"
              />
              <IonNote color="danger">{error}</IonNote>
              <IonInput
                value={email}
                type="email"
                placeholder="Email address"
                onIonChange={e => setEmail(e.detail.value)}
                className="ion-margin-vertical input-box"
              ></IonInput>
              <IonInput
                value={password}
                placeholder="Password"
                onIonChange={e => setPassword(e.detail.value)}
                type="password"
                className="ion-margin-vertical input-box"
              ></IonInput>
              <IonButton
                className="ion-margin-vertical ion-text-center primary-button sign-in-button"
                onClick={async () => {
                  setLoginClicked(true);
                  try {
                    const userCredential = await firebase
                      .auth()
                      .signInWithEmailAndPassword(email, password);

                    logTimezone(userCredential.user.uid);
                  } catch (error) {
                    setError(error.message);
                  }
                  setLoginClicked(false);
                }}
              >
                Sign in
              </IonButton>
              <IonButton
                onClick={e => setShowPasswordReset(true)}
                fill="clear"
                className="forgot-password-button"
              >
                Forgot password?
              </IonButton>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>

      <IonLoading isOpen={loginClicked} message={'Logging in...'} />
      <IonAlert
        isOpen={showPasswordReset}
        header={'Reset Password'}
        inputs={[
          {
            name: 'emailAddress',
            type: 'text'
          }
        ]}
        message={
          "If you've forgotten your password, or simply want to change it, type in your email address below. If we have your email address in our system, we'll send you an email now."
        }
        buttons={[
          {
            text: 'Cancel',
            role: 'cancel',
            handler: () => setShowPasswordReset(false)
          },
          {
            text: 'Send Reset Email',
            handler: async alertData => {
              setShowPasswordReset(false);
              try {
                await firebase
                  .auth()
                  .sendPasswordResetEmail(alertData.emailAddress);
              } catch (error) {
                firebase.firestore().collection('errors').add({
                  code: error.code,
                  message: error.message,
                  emailAddress: alertData.emailAddress
                });
              }
            }
          }
        ]}
      />
    </IonPage>
  );
};

export default Login;
