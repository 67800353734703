import { DateTime } from 'luxon';

// get user's timezone
export const getTimezone = () =>
  Intl.DateTimeFormat().resolvedOptions().timeZone;

export const sortBirthdays = state =>
  Object.keys(state.birthdays).sort((b1, b2) => {
    // calculate the next birthday
    const getNextBirthday = birthdayData => {
      let birthdayDate = DateTime.fromObject({
        month: birthdayData.dob.month,
        day: birthdayData.dob.day
      }).endOf('day');
      if (birthdayDate < DateTime.local().endOf('day')) {
        birthdayDate = birthdayDate.plus({ years: 1 });
      }

      return birthdayDate;
    };

    const b1NextBirtday = getNextBirthday(state.birthdays[b1]);
    const b2NextBirtday = getNextBirthday(state.birthdays[b2]);

    return b1NextBirtday <= b2NextBirtday ? -1 : 1;
  });
