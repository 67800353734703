import React, { useContext, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { DateTime } from 'luxon';
import {
  IonContent,
  IonHeader,
  IonPage,
  IonButtons,
  IonBackButton,
  IonToolbar,
  IonButton,
  IonLoading,
  IonList,
  IonItem,
  IonInput,
  IonNote,
  IonTextarea,
  IonSelect,
  IonSelectOption,
  IonLabel
} from '@ionic/react';
import { firebase } from '../modules/firebase';
import { AppContext } from '../data/state';
import './EditBirthday.css';

const EditBirthday = ({ history, match }) => {
  const { state } = useContext(AppContext);
  const birthday = state.birthdays[match.params.key];

  const [error, setError] = useState();
  const [name, setName] = useState(birthday.name);
  const [day, setDay] = useState(birthday.dob.day);
  const [month, setMonth] = useState(birthday.dob.month.toString());
  const [year, setYear] = useState(birthday.dob.year);
  const [notes, setNotes] = useState(birthday.notes);
  const [updateClicked, setUpdateClicked] = useState(false);

  if (!state.user) {
    return <Redirect to="/login" />;
  }

  return (
    <IonPage id="edit-birthday">
      <IonHeader>
        <IonToolbar>
          <IonButtons>
            <IonBackButton
              text={name}
              defaultHref={`/my/birthdays/view/${match.params.key}`}
            />
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <h5 className="ion-margin">Edit a birthday</h5>
        <IonNote color="danger" className="ion-margin">
          {error}
        </IonNote>
        <IonList>
          <IonItem className="pink-line">
            <IonLabel position="stacked">Name</IonLabel>
            <IonInput
              value={name}
              type="text"
              onIonChange={e => setName(e.detail.value)}
            ></IonInput>
          </IonItem>
          <IonItem className="blue-line">
            <IonLabel position="stacked">Day</IonLabel>
            <IonInput
              value={day}
              type="number"
              onIonChange={e => setDay(e.detail.value)}
            ></IonInput>
          </IonItem>
          <IonItem className="pink-line">
            <IonLabel position="stacked">Month</IonLabel>
            <IonSelect
              value={month}
              onIonChange={e => setMonth(e.detail.value)}
            >
              {[
                'January',
                'February',
                'March',
                'April',
                'May',
                'June',
                'July',
                'August',
                'September',
                'October',
                'November',
                'December'
              ].map((month, i) => (
                <IonSelectOption key={i} value={(i + 1).toString()}>
                  {month}
                </IonSelectOption>
              ))}
            </IonSelect>
          </IonItem>
          <IonItem className="blue-line">
            <IonLabel position="stacked">Year</IonLabel>
            <IonInput
              value={year}
              type="number"
              placeholder="Year"
              onIonChange={e => setYear(e.detail.value)}
            ></IonInput>
          </IonItem>
          <IonItem className="pink-line">
            <IonLabel position="stacked">Notes</IonLabel>
            <IonTextarea
              value={notes}
              rows={5}
              placeholder="Add any notes that you'll find helpful"
              onIonChange={e => setNotes(e.detail.value)}
            ></IonTextarea>
          </IonItem>
        </IonList>
        <div className="ion-text-center ion-padding-top">
          <IonButton
            className="primary-button"
            onClick={async () => {
              setUpdateClicked(true);

              const isValidDob = DateTime.fromObject({
                day,
                month
              }).isValid;
              if (!name || !day || !month || !isValidDob) {
                setError("Something isn't right. Please check your entry.");
              } else {
                try {
                  await firebase
                    .firestore()
                    .collection('users')
                    .doc(firebase.auth().currentUser.uid)
                    .collection('birthdays')
                    .doc(match.params.key)
                    .set({
                      name,
                      notes,
                      dob: {
                        day,
                        month,
                        year: year ? year : ''
                      }
                    });
                  history.goBack();
                } catch (error) {
                  setError(error.message);
                }
              }
              setUpdateClicked(false);
            }}
          >
            Update
          </IonButton>
        </div>
      </IonContent>
      <IonLoading isOpen={updateClicked} message={'Updating...'} />;
    </IonPage>
  );
};

export default EditBirthday;
