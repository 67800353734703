import React from 'react';
import { IonHeader, IonToolbar, IonTitle } from '@ionic/react';
import birthdaysAiLogo from '../images/birthdays-ai-logo-black.png';
import './MainToolbar.css';

const MainToolbar = () => {
  return (
    <IonHeader id="toolbar-header">
      <IonToolbar>
        <IonTitle className="logo">
          <img src={birthdaysAiLogo} alt="Birthdays AI logo" />
        </IonTitle>
      </IonToolbar>
    </IonHeader>
  );
};

export default MainToolbar;
