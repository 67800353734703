import React, { useContext, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { DateTime } from 'luxon';
import {
  IonBackButton,
  IonButtons,
  IonButton,
  IonContent,
  IonHeader,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonPage,
  IonToolbar,
  IonGrid,
  IonRow,
  IonCol,
  IonAlert
} from '@ionic/react';
import { firebase } from '../modules/firebase';
import { AppContext } from '../data/state';
import './ViewBirthday.css';

const ViewBirthday = ({ match, history }) => {
  const { state } = useContext(AppContext);
  const [showConfirm, setShowConfirm] = useState(false);

  if (!state.user) {
    return <Redirect to="/login" />;
  }

  const birthday = state.birthdays[match.params.key];

  if (!birthday) {
    return <Redirect to="/" />;
  }

  return (
    <IonPage id="view-birthday">
      <IonHeader translucent>
        <IonToolbar>
          <IonButtons>
            <IonBackButton
              text="Birthdays"
              defaultHref="/my/birthdays/list"
            ></IonBackButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <IonCard>
          <IonCardHeader>
            <IonCardTitle>{birthday.name}</IonCardTitle>
          </IonCardHeader>

          <IonCardContent>
            <p>
              {DateTime.fromObject({
                day: birthday.dob.day,
                month: birthday.dob.month
              }).toFormat('dd LLLL')}
              {birthday.dob.year ? ` ${birthday.dob.year}` : ''}
            </p>
            {birthday.dob.year && (
              <p>
                current age:{' '}
                {
                  DateTime.local()
                    .diff(
                      DateTime.fromObject({
                        day: birthday.dob.day,
                        month: birthday.dob.month,
                        year: birthday.dob.year
                      }),
                      ['years', 'month']
                    )
                    .toObject().years
                }
              </p>
            )}
            {birthday.notes && <p>notes: {birthday.notes}</p>}
          </IonCardContent>
        </IonCard>
        <IonGrid>
          <IonRow className="ion-justify-content-around">
            <IonCol size="3">
              <IonButton
                fill="clear"
                color="primary"
                routerLink={`/my/birthdays/edit/${match.params.key}`}
              >
                Edit
              </IonButton>
            </IonCol>
            <IonCol size="3">
              <IonButton
                color="danger"
                fill="clear"
                onClick={() => setShowConfirm(true)}
              >
                Delete
              </IonButton>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
      <IonAlert
        isOpen={showConfirm}
        header={'Delete Birthday'}
        message={`Are you sure you want to delete ${birthday.name} from your birthdays?`}
        buttons={[
          {
            text: 'Cancel',
            role: 'cancel',
            handler: () => setShowConfirm(false)
          },
          {
            text: 'Confirm',
            handler: async () => {
              setShowConfirm(false);
              await firebase
                .firestore()
                .collection('users')
                .doc(firebase.auth().currentUser.uid)
                .collection('birthdays')
                .doc(match.params.key)
                .delete();
              history.goBack();
            }
          }
        ]}
      />
    </IonPage>
  );
};

export default ViewBirthday;
