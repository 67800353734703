import React, { useContext } from 'react';
import { DateTime } from 'luxon';
import { AppContext } from '../data/state';
import {
  IonContent,
  IonList,
  IonPage,
  IonItem,
  IonLabel,
  IonIcon,
  IonCard,
  IonCardHeader,
  IonCardSubtitle,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonButton,
  IonTitle
} from '@ionic/react';
import { informationCircleOutline, addOutline } from 'ionicons/icons';
import { sortBirthdays } from '../modules/utils';
import birthdaysAiLogo from '../images/birthdays-ai-logo-black.png';
import './BirthdayList.css';

const BirthdayList = () => {
  const { state } = useContext(AppContext);

  return (
    <IonPage id="birthday-list">
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="primary">
            <IonButton routerLink="/my/birthdays/add">
              <IonIcon slot="icon-only" icon={addOutline} />
            </IonButton>
          </IonButtons>
          <IonTitle className="logo">
            <img src={birthdaysAiLogo} alt="Birthdays AI logo" />
          </IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonCard>
          <IonCardHeader>
            <IonCardSubtitle>
              <IonIcon icon={informationCircleOutline} /> Sorted by upcoming
              birthdays
            </IonCardSubtitle>
          </IonCardHeader>
        </IonCard>

        <IonList>
          {sortBirthdays(state).map((key, i) => (
            <IonItem
              key={key}
              routerLink={`/my/birthdays/view/${key}`}
              className={i % 2 === 0 ? 'blue-line' : 'pink-line'}
            >
              <IonLabel>
                <h2>{state.birthdays[key].name}</h2>
                <h3>
                  {DateTime.fromObject({
                    day: state.birthdays[key].dob.day,
                    month: state.birthdays[key].dob.month
                  }).toFormat('dd LLLL')}
                  {state.birthdays[key].dob.year
                    ? ` ${state.birthdays[key].dob.year}`
                    : ''}
                </h3>
                {state.birthdays[key].dob.year && (
                  <p>
                    current age:{' '}
                    {
                      DateTime.local()
                        .diff(
                          DateTime.fromObject({
                            day: state.birthdays[key].dob.day,
                            month: state.birthdays[key].dob.month,
                            year: state.birthdays[key].dob.year
                          }),
                          ['years', 'month']
                        )
                        .toObject().years
                    }
                  </p>
                )}
                {state.birthdays[key].notes && (
                  <p>notes: {state.birthdays[key].notes}</p>
                )}
              </IonLabel>
            </IonItem>
          ))}
        </IonList>
        {/* <IonFab vertical="bottom" horizontal="end" slot="fixed">
          <IonFabButton routerLink="/my/birthdays/add">
            <IonIcon icon={add} />
          </IonFabButton>
        </IonFab> */}
      </IonContent>
    </IonPage>
  );
};

export default BirthdayList;
