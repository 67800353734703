import React, { useContext, useState } from 'react';
import { Redirect } from 'react-router-dom';
import {
  IonContent,
  IonPage,
  IonButton,
  IonLoading,
  IonList,
  IonItem,
  IonInput,
  IonNote,
  IonTextarea,
  IonSelect,
  IonSelectOption,
  IonLabel,
  IonHeader,
  IonButtons,
  IonToolbar,
  IonBackButton
} from '@ionic/react';
import Confetti from 'react-confetti';
import { firebase } from '../modules/firebase';
import { AppContext } from '../data/state';
import { DateTime } from 'luxon';
import './AddBirthday.css';

const AddBirthday = ({ history }) => {
  const { state } = useContext(AppContext);
  const [error, setError] = useState();
  const [name, setName] = useState();
  const [day, setDay] = useState();
  const [month, setMonth] = useState('1');
  const [year, setYear] = useState();
  const [notes, setNotes] = useState();
  const [submitClicked, setSubmitClicked] = useState(false);
  const [showConfetti, setShowConfetti] = useState(false);

  if (!state.user) {
    return <Redirect to="/login" />;
  }

  return (
    <IonPage id="add-birthday">
      <IonHeader translucent>
        <IonToolbar>
          <IonButtons>
            <IonBackButton
              text="Birthdays"
              defaultHref="/my/birthdays/list"
            ></IonBackButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <h5 className="ion-margin-start">Add a birthday</h5>
        <IonNote color="danger" className="ion-margin">
          {error}
        </IonNote>
        <IonList>
          <IonItem className="pink-line">
            <IonLabel position="stacked">Name</IonLabel>
            <IonInput
              value={name}
              type="text"
              placeholder="Their name"
              onIonChange={e => setName(e.detail.value)}
            ></IonInput>
          </IonItem>
          <IonItem className="blue-line">
            <IonLabel position="stacked">Day</IonLabel>
            <IonInput
              value={day}
              placeholder="Day"
              type="number"
              onIonChange={e => setDay(e.detail.value)}
            ></IonInput>
          </IonItem>
          <IonItem className="pink-line">
            <IonLabel position="stacked">Month</IonLabel>
            <IonSelect
              value={month}
              onIonChange={e => setMonth(e.detail.value)}
            >
              {[
                'January',
                'February',
                'March',
                'April',
                'May',
                'June',
                'July',
                'August',
                'September',
                'October',
                'November',
                'December'
              ].map((month, i) => (
                <IonSelectOption key={i} value={(i + 1).toString()}>
                  {month}
                </IonSelectOption>
              ))}
            </IonSelect>
          </IonItem>
          <IonItem className="blue-line">
            <IonLabel position="stacked">Year</IonLabel>
            <IonInput
              value={year}
              placeholder="Year"
              type="number"
              onIonChange={e => setYear(e.detail.value)}
            ></IonInput>
          </IonItem>
          <IonItem className="pink-line">
            <IonLabel position="stacked">Notes</IonLabel>
            <IonTextarea
              value={notes}
              rows={5}
              placeholder="Add any notes that you'll find helpful"
              onIonChange={e => setNotes(e.detail.value)}
            ></IonTextarea>
          </IonItem>
        </IonList>

        <div className="ion-text-center ion-padding-top">
          <IonButton
            className="primary-button"
            disabled={showConfetti}
            onClick={async () => {
              setSubmitClicked(true);

              const isValidDob = DateTime.fromObject({
                day,
                month
              }).isValid;
              if (!name || !day || !month || !isValidDob) {
                setError("Something isn't right. Please check your entry.");
              } else {
                try {
                  await firebase
                    .firestore()
                    .collection('users')
                    .doc(firebase.auth().currentUser.uid)
                    .collection('birthdays')
                    .add({
                      name,
                      notes,
                      dob: {
                        day,
                        month,
                        year: year ? year : ''
                      }
                    });
                  setShowConfetti(true);
                } catch (error) {
                  setError(error.message);
                }
              }
              setSubmitClicked(false);
            }}
          >
            Submit
          </IonButton>
        </div>
      </IonContent>
      <IonLoading isOpen={submitClicked} message={'Saving...'} />;
      {showConfetti && (
        <Confetti
          recycle={false}
          height={document.body.scrollHeight}
          onConfettiComplete={() => {
            setShowConfetti(false);
            history.goBack();
          }}
        />
      )}
    </IonPage>
  );
};

export default AddBirthday;
