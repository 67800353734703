import React, { useContext } from 'react';
import { AppContext } from './data/state';
import { Route, Redirect } from 'react-router-dom';
import {
  IonRouterOutlet,
  IonTabs,
  IonTabButton,
  IonIcon,
  IonTabBar
} from '@ionic/react';
import { personOutline, homeOutline, listOutline } from 'ionicons/icons';
import BirthdayList from './pages/BirthdayList';
import ViewBirthday from './pages/ViewBirthday';
import AddBirthday from './pages/AddBirthday';
import EditBirthday from './pages/EditBirthday';
import Home from './pages/Home';
import Profile from './pages/Profile';
import './Dashboard.css';

const Dashboard = () => {
  const { state } = useContext(AppContext);

  if (!state.user) {
    return <Redirect to="/login" />;
  }

  return (
    <IonTabs>
      <IonRouterOutlet>
        <Route path="/my/:tab(home)" component={Home} exact />
        <Route path="/my/:tab(birthdays)/list" component={BirthdayList} exact />
        <Route path="/my/:tab(birthdays)/add" component={AddBirthday} exact />
        <Route
          path="/my/:tab(birthdays)/view/:key"
          component={ViewBirthday}
          exact
        />
        <Route
          path="/my/:tab(birthdays)/edit/:key"
          component={EditBirthday}
          exact
        />
        <Route path="/my/:tab(profile)" component={Profile} exact />
      </IonRouterOutlet>
      <IonTabBar slot="bottom" id="tabs">
        <IonTabButton tab="home" href="/my/home">
          <IonIcon icon={homeOutline} />
        </IonTabButton>
        <IonTabButton tab="birthdays" href="/my/birthdays/list">
          <IonIcon icon={listOutline} />
        </IonTabButton>
        <IonTabButton tab="profile" href="/my/profile">
          <IonIcon icon={personOutline} />
        </IonTabButton>
      </IonTabBar>
    </IonTabs>
  );
};

export default Dashboard;
