import React, { useContext, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { arrowBackOutline } from 'ionicons/icons';
import { AppContext } from '../data/state';
import { firebase } from '../modules/firebase';
import { getTimezone } from '../modules/utils';
import {
  IonContent,
  IonHeader,
  IonPage,
  IonButtons,
  IonIcon,
  IonToolbar,
  IonButton,
  IonLoading,
  IonList,
  IonItem,
  IonInput,
  IonNote,
  IonRow,
  IonCol,
  IonGrid
} from '@ionic/react';
import './CreateAccount.css';

const CreateAccount = () => {
  const [createAccountClicked, setCreateAccountClicked] = useState(false);
  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [error, setError] = useState();
  const { state } = useContext(AppContext);

  if (state.user) {
    return <Redirect to="/my/birthdays/list" />;
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton routerLink="/" routerDirection="back">
              <IonIcon slot="icon-only" icon={arrowBackOutline} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen className="ion-padding">
        <IonGrid>
          <IonRow>
            <IonCol
              sizeLg="4"
              offsetLg="4"
              sizeMd="6"
              offsetMd="3"
              sizeSm="8"
              offsetSm="2"
              sizeXs="12"
            >
              <h3 className="ion-margin-start">Welcome!</h3>
              <p className="ion-margin-start ion-padding-bottom">
                Let's get you started with a free account.
              </p>
              <IonNote color="danger">{error}</IonNote>
              <IonList>
                <IonItem>
                  <IonInput
                    value={name}
                    type="text"
                    placeholder="Your name"
                    onIonChange={e => setName(e.detail.value)}
                  ></IonInput>
                </IonItem>
                <IonItem>
                  <IonInput
                    value={email}
                    type="email"
                    placeholder="Email address"
                    onIonChange={e => setEmail(e.detail.value)}
                  ></IonInput>
                </IonItem>
                <IonItem>
                  <IonInput
                    value={password}
                    placeholder="Password"
                    onIonChange={e => setPassword(e.detail.value)}
                    type="password"
                  ></IonInput>
                </IonItem>
              </IonList>
              <div className="ion-text-center">
                <IonButton
                  className="primary-button register-button"
                  onClick={async () => {
                    setCreateAccountClicked(true);
                    try {
                      const userCredential = await firebase
                        .auth()
                        .createUserWithEmailAndPassword(email, password);

                      await firebase
                        .firestore()
                        .collection('users')
                        .doc(userCredential.user.uid)
                        .set({
                          name,
                          timezone: getTimezone()
                        });
                    } catch (error) {
                      setError(error.message);
                    }
                    setCreateAccountClicked(false);
                  }}
                >
                  Create account
                </IonButton>
              </div>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
      <IonLoading
        isOpen={createAccountClicked}
        message={'Creating account...'}
      />
      ;
    </IonPage>
  );
};

export default CreateAccount;
