import React, { useEffect, useContext } from 'react';
import { Route } from 'react-router-dom';
import { IonReactRouter } from '@ionic/react-router';
import { IonRouterOutlet } from '@ionic/react';
import { Plugins } from '@capacitor/core';
import {
  AppContext,
  FIREBASE_USER,
  BIRTHDAYS,
  USER_DETAILS
} from './data/state';
import Dashboard from './Dashboard';
import { firebase } from './modules/firebase';
import Landing from './pages/Landing';
import './BirthdayApp.css';
import CreateAccount from './pages/CreateAccount';

import Login from './pages/Login';

const { PushNotifications } = Plugins;

const BirthdayApp = () => {
  const { dispatch } = useContext(AppContext);
  useEffect(() => {
    let authSubscription,
      userSubscription = () => {},
      dataSubscription = () => {};
    const watchLogin = async () => {
      authSubscription = firebase.auth().onAuthStateChanged(async user => {
        dispatch({ type: FIREBASE_USER, payload: user });

        if (user) {
          firebase
            .firestore()
            .collection('users')
            .doc(user.uid)
            .onSnapshot(userSnapshot => {
              dispatch({ type: USER_DETAILS, payload: userSnapshot.data() });
            });

          dataSubscription = firebase
            .firestore()
            .collection('users')
            .doc(user.uid)
            .collection('birthdays')
            .onSnapshot(birthdaysSnapshot => {
              const birthdays = {};
              for (const birthday of birthdaysSnapshot.docs) {
                birthdays[birthday.id] = birthday.data();
              }
              dispatch({ type: BIRTHDAYS, payload: birthdays });
            });
        }
      });
    };

    watchLogin();

    return () => {
      authSubscription();
      userSubscription();
      dataSubscription();
    };
  }, [dispatch]);

  useEffect(() => {
    PushNotifications.requestPermission().then(permission => {
      console.log('checking permissions on push notifications...');
      console.log(permission);
      if (permission.granted) {
        // Register with Apple / Google to receive push via APNS/FCM
        console.log('permission was granted..');
        PushNotifications.register();
      } else {
        // No permission for push granted
        console.log('permission was not granted..');
      }
    });

    // On succcess, we should be able to receive notifications
    PushNotifications.addListener('registration', token => {
      alert('Push registration success, token: ' + token.value);
      console.log('Push registration success, token: ' + token.value);
    });

    PushNotifications.addListener('registrationError', error => {
      alert('Error on registration: ' + JSON.stringify(error));
    });

    // Show us the notification payload if the app is open on our device
    PushNotifications.addListener('pushNotificationReceived', notification => {
      console.log('pushNotificationReceived');
      console.log(notification);
    });

    // Method called when tapping on a notification
    PushNotifications.addListener(
      'pushNotificationActionPerformed',
      notification => {
        console.log('pushNotificationActionPerformed');
        console.log(notification);
      }
    );
  }, []);

  return (
    <IonReactRouter>
      <IonRouterOutlet>
        <Route path="/" component={Landing} exact />
        <Route path="/register" component={CreateAccount} exact />
        <Route path="/login" component={Login} exact />
        <Route path="/my/home" component={Dashboard} />
      </IonRouterOutlet>
    </IonReactRouter>
  );
};

export default BirthdayApp;
